import api from './api';

export const get = query => api.get(`/group${query}`);

export const getGroup = id => api.get(`/group/${id}`);

export const getGroupPermission = id => api.get(`/permission/group/${id}`);

export const addGroup = data => api.post('/group', data);

export const editGroup = (data, id) => api.put(`/group/${id}`, data);

export const updateGroupPermission = data =>
	api.post('/permission/group', data);

export const deleteGroup = id => api.delete(`/group/${id}`);
