export const GET_LIST_BUDGET = 'advertising-budget/GET_LIST_BUDGET';
export const SET_LIST_BUDGET = 'advertising-budget/SET_LIST_BUDGET';
export const DELETE_BUDGET = 'advertising-budget/DELETE_BUDGET';
export const NEW_BUDGET = 'advertising-budget/NEW_BUDGET';
export const SELECT_BUDGET = 'advertising-budget/SELECT_BUDGET';
export const MODAL_BUDGET = 'advertising-budget/MODAL_BUDGET';
export const SET_ITEMS_BUDGET = 'advertising-budget/SET_ITEMS_BUDGET';
export const EDIT_ITEM_BUDGET = 'advertising-budget/EDIT_ITEM_BUDGET';
export const ADD_ITEM_BUDGET = 'advertising-budget/ADD_ITEM_BUDGET';
export const EDIT_BUDGET = 'advertising-budget/EDIT_BUDGET';
export const DELETE_ITEM_BUDGET = 'advertising-budget/DELETE_ITEM_BUDGET';
export const RESET_ITEMS_BUDGET = 'advertising-budget/RESET_ITEMS_BUDGET';

export default {
	GET_LIST_BUDGET,
	SET_LIST_BUDGET,
	DELETE_BUDGET,
	NEW_BUDGET,
	SELECT_BUDGET,
	MODAL_BUDGET,
	SET_ITEMS_BUDGET,
	EDIT_ITEM_BUDGET,
	ADD_ITEM_BUDGET,
	EDIT_BUDGET,
	DELETE_ITEM_BUDGET,
	RESET_ITEMS_BUDGET,
};
