import {
	SET_LIST_USERS,
	SET_USER,
	SET_USER_PERMISSION,
	SET_USER_REFRESH,
} from './types';

const initialState = {
	list: false,
	user: false,
	permission: false,
	refresh: false,
};

const usersReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_LIST_USERS:
			return {
				...state,
				list: action.users,
			};
		case SET_USER:
			return {
				...state,
				user: action.user,
			};
		case SET_USER_PERMISSION:
			return {
				...state,
				permission: action.permission,
			};
		case SET_USER_REFRESH:
			return {
				...state,
				refresh: action.refresh,
			};
		default:
			return state;
	}
};

export default usersReducer;
