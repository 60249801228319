import * as types from './types';

export const getListGroups = query => ({
	type: types.GET_LIST_GROUPS,
	query,
});

export const setListGroups = groups => ({
	type: types.SET_LIST_GROUPS,
	groups,
});

export const addGroup = group => ({
	type: types.ADD_GROUP,
	group,
});

export const getGroup = group => ({
	type: types.GET_GROUP,
	group,
});

export const setGroup = group => ({
	type: types.SET_GROUP,
	group,
});

export const editGroup = (group, id) => ({
	type: types.EDIT_GROUP,
	group,
	id,
});

export const getGroupPermission = group => ({
	type: types.GET_GROUP_PERMISSION,
	group,
});

export const setGroupPermission = permission => ({
	type: types.SET_GROUP_PERMISSION,
	permission,
});

export const updateGroupPermission = (data, id) => ({
	type: types.UPDATE_GROUP_PERMISSION,
	data,
	id,
});

export const deleteGroup = group => ({
	type: types.DELETE_GROUP,
	group,
});

export default {
	getListGroups,
	setListGroups,
	addGroup,
	getGroup,
	setGroup,
	editGroup,
	getGroupPermission,
	setGroupPermission,
	updateGroupPermission,
	deleteGroup,
};
