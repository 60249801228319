import api from './api';

export const post = query =>
	api.post('/assistencia/attendance-form', query);

export const put = query =>
	api.put(`/assistencia/attendance-form/${query.data.id}`, query.data.data)

export const upload = query =>
	api.post('/assistencia/upload', query)

export const getClie = () =>
	api.get('/assistencia/get-cli')

export const getNro = cliId =>
	api.get('/assistencia/get-nro', { params: cliId })

export const getAtend = () =>
	api.get('assistencia/get-atend')

export const getSetores = () =>
	api.get('/assistencia/get-setores')

export const getServicos = () =>
	api.get('/assistencia/get-servicos')

export const getItens = () =>
	api.get('/assistencia/get-item')

export const getForms = id =>
	api.get('/assistencia/get-forms', { params: id })

export const deleteForm = id =>
	api.delete(`/assistencia/delete-form/${id}`)

export const getRowsItens = id =>
	api.get('/assistencia/get-rows-itens', { params: id })

export const getServPrev = id =>
	api.get('/assistencia/get-serv-prev', { params: id })

export const getSits = id =>
	api.get('/assistencia/get-sits', { params: id })

export  const getAnexo = id =>
	api.get('/assistencia/get-anexos', { params: id })

export const generatePdf = id =>
	api.get(`/assistencia/generatePdf/${id}`)

export const submitRP = data =>
	api.post(`/assistencia/submit-rp`, data)