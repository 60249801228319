import api from './api';

export const get = query => api.get(`/user${query}`);

export const getUser = id => api.get(`/user/${id}`);

export const getUserPermission = id => api.get(`/permission/user/${id}`);

export const activeDesactiveUser = (id, condition) =>
	api.patch(`/user/${id}`, condition);

export const updateUserPermission = data => api.post('/permission/user', data);

export const addUser = data => api.post('/user', data);

export const changePassword = (data, id) =>
	api.put(`/user/${id}/set_password`, data);

export const editUser = (data, id) => api.patch(`/user/${id}`, data);
