import * as types from './types';

export const get = query => ({
	type: types.GET_LIST_BUDGET,
	query,
});

export const set = list => ({
	type: types.SET_LIST_BUDGET,
	list,
});

export const deleteBudget = budget => ({
	type: types.DELETE_BUDGET,
	budget,
});

export const newBudget = budget => ({
	type: types.NEW_BUDGET,
	budget,
});

export const select = budget => ({
	type: types.SELECT_BUDGET,
	budget,
});

export const toogleModal = () => ({
	type: types.MODAL_BUDGET,
});

export const setItems = (item, field) => ({
	type: types.SET_ITEMS_BUDGET,
	item,
	field,
});

export const editItem = (item, index, field) => ({
	type: types.EDIT_ITEM_BUDGET,
	item,
	index,
	field,
});

export const addItem = item => ({
	type: types.ADD_ITEM_BUDGET,
	item,
});

export const editBudget = budget => ({
	type: types.EDIT_BUDGET,
	budget,
});

export const deleteItem = index => ({
	type: types.DELETE_ITEM_BUDGET,
	index,
});

export const resetItems = () => ({
	type: types.RESET_ITEMS_BUDGET,
});

export default {
	get,
	set,
	deleteBudget,
	newBudget,
	select,
	toogleModal,
	setItems,
	editItem,
	addItem,
	editBudget,
	deleteItem,
	resetItems,
};
