export const GET_INVOICE = 'invoice/GET_INVOICE';
export const SET_INVOICE = 'invoice/SET_INVOICE';
export const GET_INVOICE_BILLING = 'invoice/GET_INVOICE_BILLING';
export const SET_INVOICE_BILLING = 'invoice/SET_INVOICE_BILLING';

export const GENERATE_INVOICE_PDF = 'invoice/GENERATE_INVOICE_PDF';

export default {
	GET_INVOICE,
	SET_INVOICE,
	GET_INVOICE_BILLING,
	SET_INVOICE_BILLING,
	GENERATE_INVOICE_PDF,
};
