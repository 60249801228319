/* eslint-disable global-require */
const images = {
	logo_white: require('../../assets/img/logo/logo_white.png'),
	logo: require('../../assets/img/logo/logo_small.png'),
	sidebar: '../../assets/img/sidebar/sidebar.jpg',
	avatar: require('../../assets/img/users/user-icon.png'),
	sr: require('../../assets/img/logo/sr_service.jpeg')
};

export default images;
