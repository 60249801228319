import { combineReducers } from 'redux';
import authReducer from '../ducks/auth';
import notificationReducer from '../ducks/notification';
import apiReducer from '../ducks/api';
import usersReducer from '../ducks/users';
import groupsReducer from '../ducks/groups';
import enterpriseReducer from '../ducks/enterprise';
import genericsReducer from '../ducks/generics';
import customerSupportReducer from '../ducks/customer-support';
import invoiceReducer from '../ducks/invoice';
import advertisingBudgetReducer from '../ducks/advertising-budget';
import attendanceFormReducer from '../ducks/attendance-form';
import partsReducer from '../ducks/parts'

const rootReducer = combineReducers({
	auth: authReducer,
	notification: notificationReducer,
	api: apiReducer,
	user: usersReducer,
	group: groupsReducer,
	enterprise: enterpriseReducer,
	generics: genericsReducer,
	customerSupport: customerSupportReducer,
	invoice: invoiceReducer,
	advertisingBudget: advertisingBudgetReducer,
	attendanceForm : attendanceFormReducer,
	parts: partsReducer,
});

export default rootReducer;
