import { call, put, takeLatest, select } from 'redux-saga/effects';
import * as advertisingBudget from '../../../api/appApi/advertisingBudget';
import * as partsApi from '../../../api/appApi/parts';

import { notificationActions } from '../notification';
import * as actions from './actions';
import { apiActions, apiSelectors } from '../api';
import * as types from './types';
import * as selectors from './selectors';

export function* get(payload) {
	yield put(apiActions.apiSubmitStart());

	const { query } = payload;

	yield put(apiActions.setQueryFilter(query));

	yield put(actions.resetItems());

	console.log(query)
	try {
		const response = yield call(partsApi.get, {id: query});

		yield put(actions.set(response.data.data.response));
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao buscar peças.',
				'error',
			),
		);
	}

	yield put(apiActions.apiSubmitEnd());
}

export function* deleteParts(payload) {
	const { parts } = payload;

	try {
		const response = yield call(partsApi.remove, parts);

		if (response.status) {
			yield put(
				notificationActions.addNotification(
					'Peça deletada com sucesso!',
					'success',
				),
			);
			yield put(apiActions.toogleModal());
			const query = yield select(apiSelectors.getQuery);
			yield put(actions.get(query));
		}
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao deletar Peça.',
				'error',
			),
		);
	}
}

export function* newParts(payload) {

	const { parts } = payload
	try {
		const response = yield call(partsApi.newParts, parts);

		if (response.data) {
			yield put(actions.toogleModal());
			yield put(
				notificationActions.addNotification(
					'Sucesso ao realizar cadastro da Peça.',
					'success',
				),
			);
			yield put(actions.get());
		}
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao realizar cadastro de peça.',
				'error',
			),
		);
	}
}

export function* editPecas(payload) {
	const { pecas } = payload;
	const items = yield select(selectors.getItems);

	const edit = items.find(x => x.id === pecas);

	if (edit.id === '' && edit.cod === '' && edit.val === '') {
		yield put(
			notificationActions.addNotification(
				'Preencha todos os campos!.',
				'error',
			),
		);
	} else {
		try {
			const response = yield call(partsApi.edit, edit);
			if (response.data) {
				const index = items.findIndex(v => v.id === pecas);

				yield put(actions.deleteItem(index));
				yield put(
					notificationActions.addNotification(
						'Sucesso ao editar peça.',
						'success',
					),
				);
			}
		} catch (error) {
			yield put(
				notificationActions.addNotification(
					'Erro ao editar Peça.',
					'error',
				),
			);
		}
	}
}

export function* setItems(payload) {
	const { item } = payload;
	const items = yield select(selectors.getItems);
	const add = items.some(val => item.id === val.id);

	if (!add) {
		yield put(actions.addItem(item));
	} else {
		const { field } = payload;
		const index = items.findIndex(v => v.id === item.id);
		yield put(actions.editItem(item, index, field));
	}
}

export default function* watchParts() {
	yield takeLatest(types.GET_LIST_PARTS, get);
	yield takeLatest(types.DELETE_PARTS, deleteParts);
	yield takeLatest(types.NEW_PARTS, newParts);
	yield takeLatest(types.EDIT_PECAS, editPecas);
	yield takeLatest(types.SET_ITEMS_PARTS, setItems);
}
