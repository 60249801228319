import * as types from './types';

export const get = query => ({
	type: types.GET_LIST_PARTS,
	query,
});

export const set = list => ({
	type: types.SET_LIST_PARTS,
	list,
});

export const deleteParts = parts => ({
	type: types.DELETE_PARTS,
	parts,
});

export const newParts = parts => ({
	type: types.NEW_PARTS,
	parts,
});

export const select = parts => ({
	type: types.SELECT_PARTS,
	parts,
});

export const toogleModal = () => ({
	type: types.MODAL_BUDGET,
});

export const setItems = (item, field) => ({
	type: types.SET_ITEMS_PARTS,
	item,
	field,
});

export const editItem = (item, index, field) => ({
	type: types.EDIT_ITEM_BUDGET,
	item,
	index,
	field,
});

export const addItem = item => ({
	type: types.ADD_ITEM_PECAS,
	item,
});

export const editPecas = pecas => ({
	type: types.EDIT_PECAS,
	pecas,
});

export const deleteItem = index => ({
	type: types.DELETE_ITEM_PECAS,
	index,
});

export const resetItems = () => ({
	type: types.RESET_ITEMS_BUDGET,
});

export default {
	newParts,
	deleteParts,
	get,
	set,
	select,
	toogleModal,
	editPecas,

	
	setItems,
	editItem,
	addItem,
	deleteItem,
	resetItems,
};
