import React from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import PropTypes from '../../../lib/utils/propTypes';

const FormCard = ({ title, children, ...restProps }) => (
	<Card {...restProps}>
		<CardHeader>{title}</CardHeader>
		<CardBody>{children}</CardBody>
	</Card>
);

FormCard.propTypes = {
	title: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
};

FormCard.defaultProps = {};

export default FormCard;
