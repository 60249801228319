const env = {
	dev: 'dev',
	test: 'test',
	stg: 'stg',
	prod: 'prod',
	local: 'local',
};

const API_URL_AUTH = {
	local: 'https://id.sagasistemas.com.br/api/v1/',
	dev: '',
	test: 'https://id.sagasistemas.com.br/api/v1/',
	stg: '',
	prod: 'https://id.sagasistemas.com.br/api/v1/',
};

const APP_AUTH_VALUE = {
	local: 'portal-srengenharia',
	dev: 'portal-srengenharia',
	test: 'portal-srengenharia',
	stg: 'portal-srengenharia',
	prod: 'portal-srengenharia',
};

const API_URL_APP = {
	local: 'http://localhost:4000/api/v1',
	dev: '',
	test: 'https://api.sr.ind.br/api/v1',
	stg: '',
	prod: 'https://api.sr.ind.br/api/v1',
};

const currentEnv = env.prod;

export const BASE_AUTH = API_URL_AUTH[currentEnv];
export const APP_AUTH = APP_AUTH_VALUE[currentEnv];
export const BASE_APP = API_URL_APP[currentEnv];
