import React from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { Nav, Navbar, NavItem, NavLink as BSNavLink } from 'reactstrap';
import bn from '../../../lib/utils/bemnames';
import { navItems, routes } from '../../../routes/menu';
import R from '../../../lib/constants/R';
import SidebarPage from './SidebarPages';
import SourceLink from '../SourceLink';
import PropTypes from '../../../lib/utils/propTypes';

const sidebarBackground = {
	// backgroundImage: `url("${R.images.sidebar}")`,
	backgroundSize: 'cover',
	backgroundRepeat: 'no-repeat',
};

const bem = bn.create('sidebar');

class Sidebar extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isOpenSistema: false,
		};
	}

	handleClick = name => () => {
		this.setState(prevState => {
			const isOpen = prevState[`isOpen${name}`];

			return {
				[`isOpen${name}`]: !isOpen,
			};
		});
	};

	modulePermission = modules => {
		return modules.map(item => {
			return (
				<SidebarPage
					key={item.route.name}
					// eslint-disable-next-line react/destructuring-assignment
					isOpen={this.state[`isOpen${item.route.name}`]}
					namePages={item.route.name}
					click={name => this.handleClick(name)}
					pageContents={item.submodules}
					IconPage={item.route.icon}
				/>
			);
		});
	};

	render() {
		const permissions = [];
		const { modules } = this.props;

		if (modules) {
			modules.forEach(mod => {
				routes.forEach(route => {
					if (route.name === mod.name) {
						const aux = [];
						mod.submodules.forEach(sub => {
							route.submodules.forEach(item => {
								if (sub.id === item.id) {
									aux.push(item);
								}
							});
						});
						if (aux.length) {
							permissions.push({ route, submodules: aux });
						}
					}
				});
			});
		}

		return (
			<aside className={bem.b()} data-image={R.images.sidebar}>
				<div
					className={bem.e('background')}
					style={sidebarBackground}
				/>
				<div className={bem.e('content')}>
					<Navbar>
						<SourceLink className="navbar-brand nav-logo d-flex">
							<img
								src={R.images.logo_white}
								height="60"
								alt="SR Engenharia"
							/>
						</SourceLink>
					</Navbar>
					<Nav vertical>
						{navItems.map(({ to, name, exact, Icon }, index) => (
							<NavItem key={name} className={bem.e('nav-item')}>
								<BSNavLink
									id={`navItem-${name}-${index}`}
									className="text-uppercase"
									tag={NavLink}
									to={to}
									activeClassName="active"
									exact={exact}>
									<Icon className={bem.e('nav-item-icon')} />
									<span className="">{name}</span>
								</BSNavLink>
							</NavItem>
						))}
						{this.modulePermission(permissions)}
					</Nav>
				</div>
			</aside>
		);
	}
}

const mapStateToProps = state => {
	return {
		modules: state.enterprise.modulesCompany,
	};
};

const mapDispatchToProps = () => {
	return {};
};

Sidebar.propTypes = {
	modules: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
