import * as types from './types';

export const getCustomerSupport = (filter, userId) => ({
	type: types.GET_SCHEDULE,
	filter,
	userId,
});

export const setCustomerSupport = schedule => ({
	type: types.SET_SCHEDULE,
	schedule,
});

export const getPdf = id => ({
	type: types.GET_PDF,
	id
})
export default {
	getCustomerSupport,
	setCustomerSupport,
	getPdf
};
