import {
	MdBuild,
	MdDashboard,
	MdLocalShipping,
	MdPeople,
	MdPerson,
	MdTimelapse,
	MdDataUsage,
} from 'react-icons/md';

export const navItems = [
	{
		to: '/',
		name: 'dashboard',
		exact: true,
		Icon: MdDashboard,
	},
];

export const navAux = [
	{
		to: '/usuarios',
		name: 'usuários',
		exact: false,
		Icon: MdPerson,
		id: 103,
	},
	{
		to: '/grupos',
		name: 'grupos',
		exact: false,
		Icon: MdPeople,
		id: 104,
	},
];

export const navCenCli = [
	{
		to: '/atendimentos',
		name: 'atendimentos',
		exact: false,
		Icon: MdTimelapse,
		id: 106,
	},
	{
		to: '/notas-fiscais',
		name: 'notas fiscais',
		exact: false,
		Icon: MdDataUsage,
		id: 105,
	},
];

export const navAssisTec = [
	{
		to: '/form-atendimento',
		name: 'formulario de atendimento',
		exact: false,
		Icon: MdDataUsage,
		id:127
	},
	{
		to: '/cad-pecas',
		name: 'Cadastro de Peças',
		exact: false,
		Icon: MdDataUsage,
		id:128
	}
]

export const routes = [
	{
		name: 'Central do Cliente',
		icon: MdLocalShipping,
		submodules: navCenCli,
	},
	{
		name: 'Auxiliares',
		icon: MdBuild,
		submodules: navAux,
	},
	{
		name: 'Assistência Técnica',
		icon: MdBuild,
		submodules: navAssisTec
	}
];
