import { SET_INVOICE, SET_INVOICE_BILLING } from './types';

const initialState = {
	list: [],
	billing: [],
};

const invoiceReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_INVOICE:
			return {
				...state,
				list: action.query,
			};
		case SET_INVOICE_BILLING:
			return {
				...state,
				billing: action.query,
			};
		default:
			return state;
	}
};

export default invoiceReducer;
