import { call, put, takeLatest, select } from 'redux-saga/effects';
import * as advertisingBudget from '../../../api/appApi/advertisingBudget';
import { notificationActions } from '../notification';
import * as actions from './actions';
import { apiActions, apiSelectors } from '../api';
import * as types from './types';
import * as selectors from './selectors';

export function* get(payload) {
	yield put(apiActions.apiSubmitStart());

	const { query } = payload;

	yield put(apiActions.setQueryFilter(query));

	yield put(actions.resetItems());

	try {
		const response = yield call(advertisingBudget.get, query);

		yield put(actions.set(response.data.data));
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao buscar verbas publicitárias.',
				'error',
			),
		);
	}

	yield put(apiActions.apiSubmitEnd());
}

export function* deleteBudget(payload) {
	const { budget } = payload;

	try {
		const response = yield call(advertisingBudget.deleteBudget, budget);

		if (response.status) {
			yield put(
				notificationActions.addNotification(
					'Verba publicitária deletada com sucesso!',
					'success',
				),
			);
			yield put(apiActions.toogleModal());
			const query = yield select(apiSelectors.getQuery);
			yield put(actions.get(query));
		}
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao deletar verba publicitária.',
				'error',
			),
		);
	}
}

export function* newBudget(payload) {
	const { budget } = payload;

	try {
		const response = yield call(advertisingBudget.newBudget, budget);
		if (response.data) {
			yield put(actions.toogleModal());
			yield put(
				notificationActions.addNotification(
					'Sucesso ao realizar cadastro de verba publicitária.',
					'success',
				),
			);
		}
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao realizar cadastro de verba publicitária.',
				'error',
			),
		);
	}
}

export function* editBudget(payload) {
	const { budget } = payload;

	const items = yield select(selectors.getItems);

	const edit = items.find(x => x.id === budget);

	if (edit.start === '' || edit.end === '' || edit.percent === '') {
		yield put(
			notificationActions.addNotification(
				'Preencha todos os campos!.',
				'error',
			),
		);
	} else {
		try {
			const response = yield call(advertisingBudget.editBudget, edit);
			if (response.data) {
				const index = items.findIndex(v => v.id === budget);
				yield put(actions.deleteItem(index));
				yield put(
					notificationActions.addNotification(
						'Sucesso ao editar verba publicitária.',
						'success',
					),
				);
			}
		} catch (error) {
			yield put(
				notificationActions.addNotification(
					'Erro ao editar verba publicitária.',
					'error',
				),
			);
		}
	}
}

export function* setItems(payload) {
	const { item } = payload;
	const items = yield select(selectors.getItems);
	const add = items.some(val => item.id === val.id);

	if (!add) {
		yield put(actions.addItem(item));
	} else {
		const { field } = payload;
		const index = items.findIndex(v => v.id === item.id);
		yield put(actions.editItem(item, index, field));
	}
}

export default function* advertisingBudgetAllocation() {
	yield takeLatest(types.GET_LIST_BUDGET, get);
	yield takeLatest(types.DELETE_BUDGET, deleteBudget);
	yield takeLatest(types.NEW_BUDGET, newBudget);
	yield takeLatest(types.EDIT_BUDGET, editBudget);
	yield takeLatest(types.SET_ITEMS_BUDGET, setItems);
}
