import api from './api';


export const newParts = parts =>
	api.post('/assistencia/parts', parts);

export const get = parts =>
	api.get('/assistencia/parts', { params: parts });

export const remove = id =>
	api.delete(`/assistencia/parts/${id}`);

export const edit = parts =>
	api.put(`/assistencia/parts/${parts.id}`, parts);