// import { SET_CLASSIFICATIONS, SET_ITENS, SET_SUPPLIERS } from './types';

const initialState = {};

const genericsReducer = (state = initialState, action) => {
	switch (action.type) {
		default:
			return state;
	}
};

export default genericsReducer;
