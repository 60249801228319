import api from './api';

export const get = query =>
	api.get('/marketing/advertising-budget', { params: query });

export const deleteBudget = id =>
	api.delete(`/marketing/advertising-budget/${id}`);

export const newBudget = budget =>
	api.post('/marketing/advertising-budget', budget);

export const editBudget = budget =>
	api.put(`/marketing/advertising-budget/${budget.id}`, budget);
