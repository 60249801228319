import React from 'react';
import Authorize from './Authorize';

const DashboardPage = React.lazy(() =>
	import('screens/dashboard/DashboardPage'),
);
const ProfilePage = React.lazy(() => import('screens/profile/ProfilePage'));
const ChangePasswordPage = React.lazy(() =>
	import('screens/profile/ChangePasswordPage'),
);
const UsersPage = React.lazy(() => import('screens/users/UsersPage'));
const UserRegisterPage = React.lazy(() =>
	import('screens/users/UserRegisterPage'),
);
const UserPermissionsPage = React.lazy(() =>
	import('screens/users/UserPermissionsPage'),
);
const GroupsPage = React.lazy(() => import('screens/groups/GroupsPage'));
const GroupRegisterPage = React.lazy(() =>
	import('screens/groups/GroupRegisterPage'),
);
const GroupPermissionsPage = React.lazy(() =>
	import('screens/groups/GroupPermissionsPage'),
);
const HelpPage = React.lazy(() => import('screens/help/HelpPage'));

const CustomerSupportPage = React.lazy(() =>
	import('screens/customer-support/CustomerSupportPage'),
);
const InvoicePage = React.lazy(() => import('screens/invoice/InvoicePage'));

const AttendanceForm = React.lazy(() => import('screens/attendance-form'));

const AttendanceFormRegister = React.lazy(() => import ('screens/attendance-form/RegisterForm.js'))

const PartsPage = React.lazy(() => import('screens/parts/PartsPage.js'))

const User = Authorize(false, ['USER']);

const routes = [
	{
		path: '/',
		name: 'Home',
		component: User(DashboardPage),
		permission: false,
	},
	{
		path: '/ajuda',
		name: 'Help',
		component: User(HelpPage),
		permission: false,
	},
	{
		path: '/perfil',
		name: 'Profile',
		component: User(ProfilePage),
		permission: false,
	},
	{
		path: '/perfil/alterar-senha',
		name: 'ChangePassword',
		component: User(ChangePasswordPage),
		permission: false,
	},
	{
		path: '/usuarios',
		name: 'Users',
		component: User(UsersPage),
		permission: true,
		id: 103,
	},
	{
		path: '/usuarios/adicionar',
		name: 'UserRegister',
		component: User(UserRegisterPage),
		permission: true,
		id: 103,
	},
	{
		path: '/usuarios/editar/:id',
		name: 'UserEdit',
		component: User(UserRegisterPage),
		permission: true,
		id: 103,
	},
	{
		path: '/usuarios/permissoes',
		name: 'UserPermissions',
		component: User(UserPermissionsPage),
		permission: true,
		id: 103,
	},
	{
		path: '/grupos',
		name: 'Groups',
		component: User(GroupsPage),
		permission: true,
		id: 104,
	},
	{
		path: '/grupos/adicionar',
		name: 'GroupRegister',
		component: User(GroupRegisterPage),
		permission: true,
		id: 104,
	},
	{
		path: '/grupos/editar/:id',
		name: 'UserEdit',
		component: User(GroupRegisterPage),
		permission: true,
		id: 104,
	},
	{
		path: '/grupos/permissoes/:id',
		name: 'UserPermissions',
		component: User(GroupPermissionsPage),
		permission: true,
		id: 104,
	},
	{
		path: '/atendimentos',
		name: 'CustomerSupport',
		component: User(CustomerSupportPage),
		permission: true,
		id: 105,
	},
	{
		path: '/notas-fiscais',
		name: 'Invoice',
		component: User(InvoicePage),
		permission: true,
		id: 106,
	},
	{
		path: '/form-atendimento',
		name: 'attendanceForm',
		component: User(AttendanceForm),
		permission: true,
		id: 127,
	},
	{
		path: '/form-atendimento/register',
		name: 'attendanceFormRegister',
		component: User(AttendanceFormRegister),
		permission: true,
		id: 127,
	},
	{
		path: '/cad-pecas',
		name: 'parts',
		component: User(PartsPage),
		permission: true,
		id: 127,
	},
];

export default routes;
