import { call, put, takeLatest } from 'redux-saga/effects';
import * as invoice from '../../../api/appApi/invoice';
import { notificationActions } from '../notification';
import * as actions from './actions';
import { apiActions } from '../api';
import * as types from './types';

export function* getInvoice(payload) {
	yield put(apiActions.apiSubmitStart());

	const { query } = payload;
	const { userId } = payload;
	if (userId !== '123456789') {
		query.userId = userId;
	}

	yield put(apiActions.setQueryFilter(query));

	console.log('payload', payload);

	try {
		const response = yield call(invoice.getInvoice, query);

		yield put(actions.setInvoice(response.data.data));
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao buscar notas fiscais.',
				'error',
			),
		);
	}

	yield put(apiActions.apiSubmitEnd());
}

export function* getInvoiceBilling(selected) {
	yield put(apiActions.apiSubmitStart());

	const { query } = selected;

	try {
		const response = yield call(invoice.getInvoiceBilling, query);

		yield put(actions.setInvoiceBilling(response.data.data));
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao buscar títulos das notas fiscais.',
				'error',
			),
		);
	}

	yield put(apiActions.apiSubmitEnd());
}

export function* generateInvoicePDF(dataParam) {
	yield put(apiActions.apiSubmitStart());

	const { data } = dataParam;

	try {
		const response = yield call(invoice.generateInvoicePDF, data);

		window.open(response.data.data, '_blank');
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao buscar títulos das notas fiscais.',
				'error',
			),
		);
	}

	yield put(apiActions.apiSubmitEnd());
}

export default function* watchInvoice() {
	yield takeLatest(types.GET_INVOICE, getInvoice);
	yield takeLatest(types.GET_INVOICE_BILLING, getInvoiceBilling);
	yield takeLatest(types.GENERATE_INVOICE_PDF, generateInvoicePDF);
}
