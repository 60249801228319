export const NEW_FORM = 'attendance-form/NEW_FORM'
export const UPLOAD = 'attendance-form/UPLOAD'
export const GET_CLIE = 'attendance-form/GET_CLIE'
export const GET_NRO = 'attendance-form/GET_NRO'
export const SET_CLIE = 'attendance-form/SET_CLIE'
export const SET_NRO = 'attendance-form/SET_NRO'
export const GET_ATEND = 'attendance-form/GET_ATEND'
export const SET_ATEND = 'attendance-form/SET_ATEND'
export const GET_LIST_PREV = 'attendance-form/GET_LIST_PREV'
export const SET_lIST_PREV = 'attendance-form/SET_lIST_PREV'
export const GET_ITENS = 'attendance-form/GET_ITENS'
export const SET_ITENS = 'attendance-form/SET_ITENS'
export const GET_LIST= 'attendance-form/GET_LIST'
export const SET_LIST= 'attendance-form/SET_LIST'
export const SET_ITEMS_BUDGET= 'attendance-form/SET_ITEMS_BUDGET'
export const ADD_ITEM_FORM = 'attendance-form/ADD_ITEM_FORM'
export const EDIT_ITEM_FORM = 'attendance-form/EDIT_ITEM_FORM'
export const DELETE_FORM = 'attendance-form/DELETE_FORM'
export const ON_NAVIGATE_EDIT = 'attendance-form/ON_NAVIGATE_EDIT'
export const ON_EDIT = 'attendance-form/ON_EDIT'
export const SET_ROWS_ITENS = 'attendance-form/SET_ROWS_ITENS'
export const SET_SERV_PREV = 'attendance-form/SET_SERV_PREV'
export const SERVICOS_PREV = 'attendance-form/SERVICOS_PREV'
export const GET_SIT = 'attendance-form/GET_SIT'
export const SET_SIT = 'attendance-form/SET_SIT'
export const GENERATE_PDF = 'attendance-form/GENERATE_PDF'
export const SUBMIT_RP = 'attendance-form/SUBMIT_RP'
export const IMG_TEST = 'attendance-form/IMG_TEST'


export default {
	NEW_FORM,
	UPLOAD,
	GET_CLIE,
	GET_NRO,
	SET_CLIE,
	SET_NRO,
	GET_ATEND,
	SET_ATEND,
	GET_LIST_PREV,
	SET_lIST_PREV,
	GET_ITENS,
	SET_ITENS,
	GET_LIST,
	SET_LIST,
	SET_ITEMS_BUDGET,
	ADD_ITEM_FORM,
	EDIT_ITEM_FORM,
	DELETE_FORM,
	ON_NAVIGATE_EDIT,
	ON_EDIT,
	SET_ROWS_ITENS,
	SET_SERV_PREV,
	SERVICOS_PREV,
	SET_SIT,
	GET_SIT,
	GENERATE_PDF,
	SUBMIT_RP,
	IMG_TEST
};
