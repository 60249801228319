const colors = {
	colorPrimary: '#1A4242',
	colorSecondary: '#6EB08C',
	colorSuccess: '#45b649',
	colorDanger: '#f85032',
	colorWarning: '#ffd700',
	colorInfo: '#00c9ff',
};

export default colors;
