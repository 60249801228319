import { fork } from 'redux-saga/effects';
import watchUserAuthentication from '../ducks/auth/sagas';
import watchUsers from '../ducks/users/sagas';
import watchGroups from '../ducks/groups/sagas';
import watchEnterprise from '../ducks/enterprise/sagas';
import watchCustomerSupport from '../ducks/customer-support/sagas';
import watchInvoice from '../ducks/invoice/sagas';
import advertisingBudgetAllocation from '../ducks/advertising-budget/sagas';
import watchAttendanceForm from '../ducks/attendance-form/sagas';
import watchParts from '../ducks/parts/sagas'

export default function* startForman() {
	yield fork(watchUserAuthentication);
	yield fork(watchUsers);
	yield fork(watchGroups);
	yield fork(watchEnterprise);
	yield fork(watchCustomerSupport);
	yield fork(watchInvoice);
	yield fork(advertisingBudgetAllocation);
	yield fork(watchAttendanceForm);
	yield fork(watchParts);

}
