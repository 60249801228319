import React, { useEffect, useState } from 'react';
import PropTypes from '../../../lib/utils/propTypes';

const Page404 = ({ location }) => {
	const [ready, setReady] = useState(0);

	useEffect(() => {
		setTimeout(() => {
			setReady(1);
		}, 700);
	}, []);

	return (
		<div className="ml-4 mt-4">
			{ready === 1 && (
				<h3>
					Não foi encontrado uma rota para
					<code>{location.pathname}</code>
				</h3>
			)}
		</div>
	);
};

Page404.propTypes = {
	location: PropTypes.shape({ pathname: PropTypes.string }).isRequired,
};

export default Page404;
