import { call, put, takeLatest } from 'redux-saga/effects';
import * as customerSupport from '../../../api/appApi/customerSupport';
import { notificationActions } from '../notification';
import * as actions from './actions';
import { apiActions } from '../api';
import * as types from './types';

export function* getCustomerSupport(payload) {
	yield put(apiActions.apiSubmitStart());

	const { filter } = payload;
	const { userId } = payload;
	if (userId !== '123456789') {
		filter.userId = userId;
	}

	yield put(apiActions.setQueryFilter(filter));

	try {
		const response = yield call(customerSupport.get, filter);

		yield put(actions.setCustomerSupport(response.data.data));
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao buscar programação programada.',
				'error',
			),
		);
	}

	yield put(apiActions.apiSubmitEnd());
}

export function* generatePdf(payload) {
	yield put(apiActions.apiSubmitStart());

	console.log(payload)
	const id = payload.id

	try {
		const response = yield call(customerSupport.getPdf, {id: id});
		window.open(response.data.data, '_blank');

	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao gerar pdf.',
				'error',
			),
		);
	}
}

export default function* watchCustomerSupport() {
	yield takeLatest(types.GET_SCHEDULE, getCustomerSupport);
	yield takeLatest(types.GET_PDF, generatePdf);

}
