import * as types from './types';

export const getInvoice = (query, userId) => ({
	type: types.GET_INVOICE,
	query,
	userId,
});

export const setInvoice = query => ({
	type: types.SET_INVOICE,
	query,
});

export const getInvoiceBilling = query => ({
	type: types.GET_INVOICE_BILLING,
	query,
});

export const generateInvoicePDF = data => ({
	type: types.GENERATE_INVOICE_PDF,
	data,
});

export const setInvoiceBilling = query => ({
	type: types.SET_INVOICE_BILLING,
	query,
});

export default {
	getInvoice,
	setInvoice,
	getInvoiceBilling,
	generateInvoicePDF,
	setInvoiceBilling,
};
