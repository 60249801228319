import {
	SET_LIST_BUDGET,
	SELECT_BUDGET,
	MODAL_BUDGET,
	ADD_ITEM_BUDGET,
	EDIT_ITEM_BUDGET,
	RESET_ITEMS_BUDGET,
	DELETE_ITEM_BUDGET,
} from './types';

const initialState = {
	list: [],
	budget: false,
	modal: false,
	items: [],
};

const advertisingBudgetReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_LIST_BUDGET:
			return {
				...state,
				list: action.list,
			};
		case SELECT_BUDGET:
			return {
				...state,
				budget: action.budget,
			};
		case MODAL_BUDGET:
			return {
				...state,
				modal: !state.modal,
			};
		case ADD_ITEM_BUDGET:
			return {
				...state,
				items: state.items.concat(action.item),
			};
		case EDIT_ITEM_BUDGET:
			return {
				...state,
				items: state.items.map((i, key) =>
					key === action.index
						? { ...i, [action.field]: action.item[action.field] }
						: i,
				),
			};
		case RESET_ITEMS_BUDGET:
			return {
				...state,
				items: [],
			};
		case DELETE_ITEM_BUDGET:
			return {
				...state,
				items: state.items.filter((_, i) => i !== action.index),
			};
		default:
			return state;
	}
};

export default advertisingBudgetReducer;
