import * as types from './types';

export const getListUsers = query => ({
	type: types.GET_LIST_USERS,
	query,
});

export const setListUsers = users => ({
	type: types.SET_LIST_USERS,
	users,
});

export const addUser = user => ({
	type: types.ADD_USER,
	user,
});

export const getUser = user => ({
	type: types.GET_USER,
	user,
});

export const setUser = user => ({
	type: types.SET_USER,
	user,
});

export const editUser = (user, id) => ({
	type: types.EDIT_USER,
	user,
	id,
});

export const getUserPermission = user => ({
	type: types.GET_USER_PERMISSION,
	user,
});

export const setUserPermission = permission => ({
	type: types.SET_USER_PERMISSION,
	permission,
});

export const activeDesactiveUser = user => ({
	type: types.ACTIVE_DESACTIVE_USER,
	user,
});

export const setRefresh = refresh => ({
	type: types.SET_USER_REFRESH,
	refresh,
});

export const updateUserPermission = (data, id) => ({
	type: types.UPDATE_USER_PERMISSION,
	data,
	id,
});

export const changePassword = password => ({
	type: types.CHANGE_PASSWORD,
	password,
});

export const editUserProfile = data => ({
	type: types.EDIT_USER_PROFILE,
	data,
});

export default {
	getListUsers,
	setListUsers,
	addUser,
	getUser,
	setUser,
	editUser,
	getUserPermission,
	setUserPermission,
	activeDesactiveUser,
	setRefresh,
	updateUserPermission,
	changePassword,
	editUserProfile,
};
