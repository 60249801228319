import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import PageSpinner from '../Page/PageSpinner';
import Page404 from '../Page/Page404';
import PropTypes from '../../../lib/utils/propTypes';

const RenderRoutes = ({ routes }) => {
	const modules = useSelector(state => state.enterprise.modulesCompany);

	const alllowed = [];

	if (modules) {
		modules.forEach(mod => {
			routes.forEach(route => {
				if (route.permission) {
					mod.submodules.forEach(sub => {
						if (sub.id === route.id) {
							alllowed.push(route);
						}
					});
				}
			});
		});
		routes.forEach(route => {
			if (!route.permission) {
				alllowed.push(route);
			}
		});
	}

	return (
		<React.Suspense fallback={<PageSpinner />}>
			<Switch>
				{alllowed.map(route => (
					<Route
						exact
						path={route.path}
						key={route.path}
						component={route.component}
					/>
				))}
				<Route component={Page404} />
			</Switch>
		</React.Suspense>
	);
};

RenderRoutes.propTypes = {
	routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default RenderRoutes;
