import { SET_SCHEDULE } from './types';

const initialState = {
	schedule: [],
};

const customerSupportReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_SCHEDULE:
			return {
				...state,
				schedule: action.schedule,
			};
		default:
			return state;
	}
};

export default customerSupportReducer;
