
export const DELETE_BUDGET = 'advertising-budget/DELETE_BUDGET';
export const EDIT_ITEM_BUDGET = 'advertising-budget/EDIT_ITEM_BUDGET';
export const RESET_ITEMS_BUDGET = 'advertising-budget/RESET_ITEMS_BUDGET';


export const NEW_PARTS = 'parts/NEW_PARTS'
export const GET_LIST_PARTS = 'parts/GET_LIST_PARTS'
export const SET_LIST_PARTS = 'parts/SET_LIST_PARTS'
export const SELECT_PARTS = 'parts/SELECT_PARTS'
export const DELETE_PARTS = 'parts/DELETE_PARTS'
export const SET_ITEMS_PARTS = 'parts/SET_ITEMS_PARTS'
export const MODAL_BUDGET = 'advertising-budget/MODAL_BUDGET';
export const ADD_ITEM_PECAS = 'parts/ADD_ITEM_PECAS'
export const EDIT_PECAS = 'parts/EDIT_PECAS'
export const DELETE_ITEM_PECAS = 'parts/DELETE_ITEM_PECAS'

export default {
	NEW_PARTS,
	GET_LIST_PARTS,
	SET_LIST_PARTS,
	SELECT_PARTS,
	DELETE_PARTS,
	SET_ITEMS_PARTS,
	MODAL_BUDGET,
	ADD_ITEM_PECAS,
	EDIT_PECAS,
	DELETE_ITEM_PECAS,


	EDIT_ITEM_BUDGET,
	RESET_ITEMS_BUDGET,
};
